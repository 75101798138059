//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonLink from '@/shared/components/ButtonLink.vue';

export default {
  name: 'DownloadBrochureForm',
  components: {
    ButtonLink,
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { windowMatchMediaMixin } from '~/mixins/matchMedia';
import DownloadAppIcons from '~/components/storyblok/DownloadAppIcons/DownloadAppIcons.vue';
import EmailBrochureForm from './EmailBrochureForm.vue';
import DownloadBrochureForm from './DownloadBrochureForm.vue';

const appIcons = {
  appleBranchLink: {
    url: 'https://fightcamp.app.link/y3nZxmYIDob',
    linktype: 'url',
    fieldtype: 'multilink',
    cached_url: 'https://fightcamp.app.link/y3nZxmYIDob',
  },
  googleBranchLink: {
    id: '',
    url: 'https://fightcamp.app.link/76ZCQs5IDob',
    linktype: 'url',
  },
  iconDimensions: {
    apple: {
      mobileHeight: '36px',
      tabletUpHeight: '48px',
    },
    google: {
      mobileHeight: '48px',
      tabletUpHeight: '66px',
    },
  },
};

export default {
  name: 'TopFooterForm',
  mixins: [windowMatchMediaMixin],
  components: {
    EmailBrochureForm,
    DownloadBrochureForm,
    DownloadAppIcons,
  },
  data() {
    return {
      isNotSubmitted: true,
      appIconsData: appIcons,
    };
  },
  computed: {
    view_name() {
      return `${this.$route.name}_${this.$options.name}`;
    },
    fcLogoWidth() {
      return this.mixinMatchMedia.isTabletUp ? '119px' : '84px';
    },
    fcLogoHeight() {
      return this.mixinMatchMedia.isTabletUp ? '89px' : '67px';
    },
  },
  methods: {
    getLinkName(name) {
      return `${this.view_name}_${name}`;
    },
    handleSubmission() {
      this.isNotSubmitted = !this.isNotSubmitted;
    },
  },
};

































import { defineComponent, computed, ComputedRef } from '@vue/composition-api';
import { isTabletUpEffect } from '~/effects/matchMedia';

interface IconStyle {
  width: string | null;
  height: string | null;
}

interface IconDimension {
  [key: string]: {
    mobileHeight: string;
    mobileWidth: string;
    tabletUpHeight: string;
    tabletUpWidth: string;
  };
}

const iconDimensions: IconDimension = {
  apple: {
    mobileHeight: '40px',
    mobileWidth: '120px',
    tabletUpHeight: '48px',
    tabletUpWidth: '143px',
  },
  google: {
    mobileHeight: '58px',
    mobileWidth: '150px',
    tabletUpHeight: '68px',
    tabletUpWidth: '175px',
  },
};

export default defineComponent({
  name: 'DownloadAppIcons',
  props: {
    appleBranchLink: {
      type: Object,
      default: () => ({ url: '' }),
    },
    googleBranchLink: {
      type: Object,
      default: () => ({ url: '' }),
    },
    showAppleIcon: {
      type: Boolean,
      default: true,
    },
    showGoogleIcon: {
      type: Boolean,
      default: true,
    },
    iconDimensions: {
      type: Object,
      default: () => iconDimensions,
    },
  },
  setup(props) {
    const isTabletUp = isTabletUpEffect();

    const platforms = ['apple', 'google'];
    const iconStyles: { appleIconStyles: ComputedRef<IconStyle>, googleIconStyles: ComputedRef<IconStyle> } = {
      appleIconStyles: computed(() => ({ width: null, height: null })),
      googleIconStyles: computed(() => ({ width: null, height: null })),
    };

    // get dimensions from props, if not in props, use default dimensions
    platforms.forEach(platform => {
      const dimensions = props.iconDimensions[platform] || iconDimensions[platform];
      const styles = `${platform}IconStyles` as 'appleIconStyles' | 'googleIconStyles';

      iconStyles[styles] = computed(() => ({
        height: isTabletUp.value ? dimensions.tabletUpHeight : dimensions.mobileHeight,
        width: isTabletUp.value ? dimensions.tabletUpWidth : dimensions.mobileWidth,
      }));
    });

    return {
      appleIconStyles: iconStyles.appleIconStyles,
      googleIconStyles: iconStyles.googleIconStyles,
      getLinkName: (link: string) => `DownloadAppIcons_${link}`,
    };
  },
});

